import logo from "./logo.webp"

const clientData = {
    client_entity: 29,
    attorney_firm: 'Leggett Clemons Crandall',
    attorney_name: 'Steve Clemons',
    attorney_number: '214-473-8686',
    attorney_email: 'sclemons@lcclawfirm.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_lcc+(720p).mp4',
    mainColor: '#D3BC90',
    secondaryColor: '#AF864D',
    siteLink: 'https://www.lcclawfirm.com',
    logo
}

export default clientData